




























































































































































































































































































































































































































































































































































































































































































.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.statistics{
  margin-bottom: 10px;
  >span + span{
    margin-left: 10px;
  }
}
